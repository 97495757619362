import { FormEvent, useEffect, useState } from 'react'
import Footer from '../layout/Footer'
import { load } from '@cashfreepayments/cashfree-js';
import axios from 'axios';
import { useStateContext } from '../components/Constants/ContextProvider';
import '../components/UI/Radio.css'
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Pop from '../components/offer/Pop';

const Register = () => {

  const [message,setMessage] = useState<any>()
  const [committees, setCommittees] = useState<string>('')
  const [cashfree, setCashfree] = useState<any>(null);
  const [paymentSessionId, setPaymentSessionId] = useState<any>('');
  // const [orderData, setOrderData] = useState<any>(null);
  const [orderId,setOrderId] = useState<string>('')
  const [referId,setReferId] = useState<string>('')
  
  
  const url = "https://iitminternware.com/matrix_backend"
  const {currentUser, studentData, setUmungData} = useStateContext()

  const navigate = useNavigate()
  
  let payment = ((studentData?.collageName === 'IITM' || studentData?.collageName === 'IPITM' || studentData?.collageName ==='IINTM') ? '750' : '1100' )

  const sessionGenratior=async()=>{
   if(committees !== '' ){ try{
     setOrderId(`order_${new Date().getTime()}`)
      const response = await axios.post(`${url}/create-order`, {
        // order_amount: payment.toString(),
        order_amount: payment,
        order_id: orderId,
        order_currency: 'INR',
        customer_details: {
          customer_id: currentUser?.uid,
          customer_name: studentData?.name,
          customer_email: currentUser?.email,
          customer_phone: studentData?.phoneNumber, // Ensure this is a valid phone number with max 20 chars
        }
      });
    
    console.log(response.data); // Log the response data

    if (response.data && response.data.payment_session_id) {
      setPaymentSessionId(response.data.payment_session_id); // Store the payment session ID
      toast.success('Order confirmed! You can now proceed to payment. 🤩');
    } else {
      alert('Failed to retrieve payment session ID.');
    }
  }
  catch(error){
    console.log(error)
  }}
  else{
    toast.error("No committee selected 🫠")
  }
  }
  
  const handlePayNow = async() => {
    if (!paymentSessionId && orderId !== '') {
      alert('Error Occurred while generation of Order ID 😰.');
      return;
    }
  
    if (cashfree && (studentData?.name !== '' || studentData?.name !== undefined)) {
      const checkoutOptions = {
        paymentSessionId: paymentSessionId, // Use paymentSessionId from response
        redirectTarget: '_modal', // Opens the payment modal
      };
  
      await cashfree.checkout(checkoutOptions).then((result:any) => {
        if (result.paymentDetails){
          setMessage(result.paymentDetails.paymentMessage)
          registerStudent()
        }
      }).catch((error: any) => {
        console.error('Error during payment:', error);
        alert('Error during payment. Please try again.');
      });
    } else {
      toast.error("Some Network Error Occurred Try To Connect With A Stable Network Or Refresh The Page 😴")
    }
  };

  useEffect(()=>{
      const initializeSDK = async () => {
        try {
          // const sdk:any = await load({ mode: 'sandbox' });
          const sdk = await load({ mode: 'production' });
          setCashfree(sdk);
        } catch (error) {
          console.log('Error initializing Cashfree SDK:', error);
        }
      };

      initializeSDK()
      
    },[committees])



  console.log(paymentSessionId)

  // const handlePayNow = async(e:FormEvent)=>{
  //   e.preventDefault()

  //   try {

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

// console.log(paymentSessionId)

const data = {
  name:studentData?.name,
  email:studentData?.email,
  number:studentData?.phoneNumber,
  collageName:studentData?.collageName,
  course:studentData?.course,
  year:studentData?.year,
  // cost:payment.toString(),
  cost:payment,
  paymentID:paymentSessionId,
  payment_message:"Online-Payment-successful",
  orderId:orderId,
  // orderId:'test',
  committeeName:committees,
  uid:currentUser?.uid,
  referId:referId,
}


const getRegisdata =async()=>{
  await axios.get(`https://student-data2024-eosin.vercel.app/api/umung/getStudentByUid?id=${currentUser?.uid}`).then((res)=>setUmungData(res.data))
}

const sendEmail =async()=>{
  await axios.post('https://student-data2024-eosin.vercel.app/api/umung/sendMail',{
    name:studentData?.name,
    email:studentData?.email,
  }).then((res)=>{toast.success('Confirmation Mail Send..! 😏')
    console.log(res)
  }).catch((res)=>console.log(res))
  navigate('/Home')
}

const registerStudent=async()=>{

  await axios.post('https://student-data2024-eosin.vercel.app/api/umung/registerStudent',data).then((res)=>{
    console.log(res)
    toast.success("Payment Received successfully..! 🥳")
    sendEmail()
    getRegisdata()
  }).catch((res)=>{
    console.log(res)
    toast.error("Some error occurred 😰")
  })
}

console.log(data)


const committeess = [

  //  'United Nations General Assembly (UNGA)',
  'United Nations Security Council (UNSC)',
  // 'United Nations Human Rights Council (UNHRC)',
  // 'LOK SABHA',
  'North Atlantic Treaty Organization (NATO)',
  'International Press (IP)',
  'International Olympics and Paralympics Committee (IOPC)',
];


// ashish pop



const [showPop , setShowPop] = useState(false)

// const [selectedCommittee, setSelectedCommittee] = useState<string>('');

//   const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const value = e.target.value;

//     if (value === 'United Nations Human Rights Council (UNHRC)') {
//       alert('United Nations Human Rights Council (UNHRC) is full. Please select another committee.');
//       setSelectedCommittee(''); // Deselects "UNHRC" after showing the alert
//     } else {
//       setSelectedCommittee(value); // Sets other options normally
//     }
//   };

// ashish pop

  return (
    <div className='w-full h-full'>
        <div className='w-full flex flex-col items-center justify-center pt-28 '>
        
        <div className="flex flex-col    text-center mb-4 border-slate-800 w-[40rem] max-sm:w-[80%] mx-auto">

      {/* <label htmlFor="inputBox" className="border border-[#863393] rounded-2xl text-[2em]  max-sm:text-[1.2em] mb-2 text-white bg-[#863393] text-center  font-semibold py-2 px-4">
      Registration Amount ₹{payment}
      </label> */}

      {/* <label htmlFor="inputBox" className="border rounded-2xl text-[2em]  max-sm:text-[1.1em] mb-2 text-white bg-[#ffffff] text-center  font-semibold py-2 px-4">
      <Link to="https://docs.google.com/spreadsheets/d/1yyqT61NWVBA3m5XwN44zFmE0L42GobLSugqVVk2cxtk/edit" className='bg-white text-black font-bold ' target='_blank'>
          Select your matrice from here!
          </Link>
      </label> */}

      {/* <Link to="#" className='bg-white' target='_blank'>
          Select your matrice from here!
          </Link> */}
    </div>

          <div className="w-[40rem] max-sm:w-[80%] mx-auto">
      <table className="table-auto w-full ">
        <thead className=''>
          <tr>

            {/* <th colSpan={2} className=" text-white  bg-[#863393] border-2 rounded-3xl text-center text-[2em]  max-sm:text-[1.2em] px-2 py-2">
              Registration Amount ₹{payment}
            </th> */}

            <th colSpan={2} className=" text-white  bg-[#863393] border text-center text-[2em]  max-sm:text-[1.2em] px-2 py-2">
              Registration Amount 
              
            </th>
          </tr>
          
          {/* <tr>
          <th colSpan={2} className="border-2 rounded-3xl text-black  bg-[#fdfdfd] text-center text-[2em]  max-sm:text-[1.2em] px-2 py-2"> 
         
            </th>
            </tr> */}
        </thead>
        <tbody className='text-white text-center text-[1.4em] max-sm:text-[1.1em] '>
          <tr>
            <td className="border border-gray-400 px-4 py-2">IITM Student</td>
            <td className="border border-gray-400 px-4 py-2">Rs. 750</td>
          </tr>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Outside IITM</td>
            <td className="border border-gray-400 px-4 py-2">Rs. 1100</td>
          </tr>
        </tbody>
      </table>
    </div>
       
       {/* ashish */}
       {/* <div className='text-white'>
        <button onClick={ () => setShowPop(true)}>REDEEM OFFER</button>
         {showPop &&  <Pop/>}
        
        </div>  */}

    <div className="mx-auto mt-6 text-center">
      <label htmlFor="committee" className="text-5xl text-center max-sm:text-[2em] font-semibold  text-white ">
        Select Your Committee
      </label>
    <div className='w-full py-12 flex justify-center text-center'>
    <div className="relative group rounded-lg  text-center text-[1.4em] w-[40rem] max-sm:w-[80%] overflow-hidden before:absolute before:w-12 before:h-12  before:right-0  before:rounded-full ">
      
      <select value={committees} onChange={(e)=>setCommittees(e.target.value)} className=" appearance-none  relative justify-center text-white bg-[#863393]  outline-none border border-neutral-500   text-[0.8rem] md:text-[1.5rem] font-bold rounded-lg  block w-[100%] mx-auto text-center  p-2.5  max-sm:text-[.4em] ">
      <option value="" className='text-[.8em]' >Click here to select</option>
      
      
      
      {committeess.map((committee, index) => (
              <option key={index} value={committee} className='text-[.6em]'>
                {committee}
              </option>
            ))}

<option value=""  className='text-[.6em] text-white bg-[#b447c5]' >United Nations Human Rights Council (UNHRC) - FULL</option>

      <option value=""  className='text-[.6em] text-white bg-[#b447c5]' >United Nations General Assembly (UNGA) - FULL</option>
      <option value=""  className='text-[.6em] text-white bg-[#b447c5]' >LOK SABHA - FULL</option>
      </select>
      

{/* <select
      value={selectedCommittee}
      onChange={handleSelectChange}
      className="appearance-none relative justify-center text-white bg-[#863393] outline-none border border-neutral-500 text-[0.8rem] md:text-[1.5rem] font-bold rounded-lg block w-[100%] mx-auto text-center p-2.5 max-sm:text-[.4em]"
    >
      <option value="" className="text-[.8em]">
        Click here to select
      </option>
      {committeess.map((committee, index) => (
        <option
          key={index}
          value={committee}
          className="text-[.6em]"
        >
          {committee}
        </option>
      ))}
    </select> */}

    </div>
    </div>
    </div>
{/* ashish */}

{/* ashish */}
  
  <div className="flex flex-col  bg-[#863393] rounded-md text-center mb-4 border-2 border-slate-800">
      <label htmlFor="inputBox" className="text-[1.2em] mb-2 text-white bg-[#863393] text-center  font-semibold py-2 px-4">
        Enter Referral Code <span className='text-[.9em] text-white text-opacity-65'>(Optional)</span>
      </label>
      <input
        id="inputBox"
        type="text"
        placeholder="Enter Referral Code "
        className="border-t-2 border-slate-800 bg-slate-200 px-4 py-2 rounded-b-md placeholder:text-gray-700"
        onChange={(e)=>setReferId(e.target.value)}
      />
    </div> 

{/* ashish */}

        </div>
        <div className='text-center'>
      {(paymentSessionId === '') && <button className='flex justify-center gap-5 items-center mx-auto shadow-xl text-[1.5em] bg-[#321237] backdrop-blur-md 3xl:font-medium isolation-auto border-[#321237] before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-xl before:bg-[#863393] hover:text-[#ededed] before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-5 py-3 overflow-hidden border-2 hover:border-[#863393] rounded-xl group text-white' onClick={()=>sessionGenratior()}>
            Confirm order
            </button>}
          {(paymentSessionId !== '') &&<button className='flex justify-center gap-5 items-center mx-auto shadow-xl text-[1.5em] bg-[#321237] backdrop-blur-md 3xl:font-medium isolation-auto border-[#321237] before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-xl before:bg-[#863393] hover:text-[#ededed] before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-5 py-3 overflow-hidden border-2 hover:border-[#863393] rounded-xl group text-white' onClick={(e)=>handlePayNow()}>
            Pay Now
          </button>}
        </div>
        
        

      <Footer/>
    </div>
  )
}

export default Register
