// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDNwOBWKKPm53W8NeayyodVeeOSNp_4B20",
  authDomain: "iwstudent-69f78.firebaseapp.com",
  projectId: "iwstudent-69f78",
  storageBucket: "iwstudent-69f78.appspot.com",
  messagingSenderId: "395067617861",
  appId: "1:395067617861:web:af86d71a4fdb30d7d5692c",
  measurementId: "G-6S5TC2NSXC"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth()