import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Contact = () => {
  return (
    <ContactContainer className='font-ubuntu '>
<h1 className='text-center text-[5em] max-sm:text-[3.1em] md:text-[5.5em] text-[#bd71e3] mt-10'>Contact Details</h1>

<div className='text-center mt-2 flex flex-col justify-center'>
  <h1 className='text-[2.5em] max-sm:text-[1.8em] md:text-[2.8em]  text-[#bd71e3] text-center'>PHONE NUMBERS</h1>
<p className='text-white text-lg md:text-2xl max-sm:text-[1em]'>Diya Kalra : +91 9650078119</p>
<p className='text-white text-lg md:text-2xl max-sm:text-[1em]'>Suhani Gupta : +91 9870424494</p>
<p className='text-white text-lg md:text-2xl max-sm:text-[1em]'>Satyam Arora : +91 7428071306</p>
<p className='text-white text-lg md:text-2xl max-sm:text-[1em]'>Shubh Sharma : +91 8800258078</p>
</div>

<div className=' mt-7 text-center'>
  <h1 className='text-[2.5em] max-sm:text-[2em] md:text-[2.8em] text-[#bd71e3] text-center'>LINKS</h1>
<p className='text-white text-xl md:text-2xl max-sm:text-[1em]'><Link to="www.iitminternware.com">www.iitminternware.com</Link></p>
<p className='text-white text-xl md:text-2xl max-sm:text-[1em]'>For queries : <Link to="queries@iitminternware.com">queries@iitminternware.com</Link></p>
</div>

<div className=' mt-7 text-center'>
  <h1 className='text-[2.5em] max-sm:text-[2em] md:text-[2.8em] text-[#bd71e3] text-center'>ADDRESS</h1>
<p className='mx-1 text-white text-[1.3em] md:text-2xl max-sm:text-[1em]'>D-29, Janakpuri Institutional Area, Janakpuri, New Delhi, Delhi, 110058</p>
</div>
    </ContactContainer>
  )
}


const ContactContainer = styled.div`

`
export default Contact
