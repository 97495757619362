import React from 'react'
import styled from 'styled-components'

const List = () => {
  return (
   <ListContainer className='flex m-auto w-full flex-wrap  md:gap-[5rem] max-sm:gap-7 text-center justify-center items-center mt-10'>
   

    <div className="flip-card" >
  <div className="flip-card-inner">
    <div className="flip-card-front">
     <img src="assets/logoUNGA.png" alt="" className='w-[75%]'/>
     <h1>United Nations General Assembly </h1>
    </div>
 
    <div className="flip-card-back">
      <h3 className=''>A platform for all UN member states to engage in global discussions, promoting peace, security, and cooperation on international issues. It offers a forum for debate and decision-making on a wide range of global challenges. <br /> <br />
      <span className='text-[1.5em]'>Agenda -</span> The role of AI in Modern Warfare: Assessing the Need for International Regulations to prevent the Militarization of Al and Autonomous Weapons.
      </h3>
    </div>
  </div>
</div>
<div className='flex m-auto w-full flex-wrap  md:gap-[5rem] max-sm:gap-5 text-center justify-center items-center mt-10'>



<div className="flip-card" >
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <img src="assets/UNHRC.png" alt="" className='w-[75%]'/>
      <h1>United Nations Security Council  </h1>
    </div>
    <div className="flip-card-back">
    <h3 className=''>Responsible for maintaining international peace and security, the UNSC works on conflict resolution and authorizes peacekeeping missions. Its decisions have a significant impact on global stability and security. <br /> <br />
      <span className='text-[1.5em]'>Agenda -</span> Ensuring the Protection of Civilians in Armed Conflicts: Evaluating the Responsibility to Protect (R2P) Doctrine.
      </h3>
    </div>
  </div>
</div>

<div className="flip-card" >
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <img src="assets/logoUNSC.png" alt=""className='w-[75%]' />
      <h1>United Nations Human Rights Council  </h1>
    </div>
    <div className="flip-card-back">
    <h3 className=''>Focuses on protecting and promoting human rights worldwide, addressing violations and fostering international dialogue on human rights challenges. It plays a crucial role in shaping global human rights policies and responses. <br /> <br />
      <span className='text-[1.5em]'>Agenda -</span> Preventing conflict and promoting peace in fragile states with special emphasis on Geopolitical stabilization.
      </h3>
    </div>
  </div>
</div>

<div className="flip-card" >
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <img src="assets/loksabhafinall.png" alt="" className='w-[75%]'/>
      <h1>LOK SABHA  </h1>
    </div>
    <div className="flip-card-back">
    <h3 className=''>The lower house of India’s Parliament, where members debate and legislate on key issues impacting the country, with a focus on national policies and reforms. It plays a vital role in shaping India’s legislative framework and governance.<br /> <br />
      <span className='text-[1.5em]'>Agenda -</span> Enhancing Legal Frameworks and Law Enforcement Mechanisms to Ensure Women’s
Safety in Public and Private Spaces.
      </h3>
    </div>
  </div>
</div>


<div className="flip-card" >
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <img src="assets/nato logo finalalal.png" alt="" className='w-[75%]' />
      <h1>North Atlantic Treaty Organization   </h1>
    </div>
    <div className="flip-card-back">
    <h3 className=''>The NATO committee addresses global security and defence cooperation among member states.
    Delegates will discuss issues like terrorism, cyber threats, and geopolitical conflicts, working together to promote peace, stability, and diplomatic solutions. 
      </h3>
    </div>
  </div>
</div>

<div className="flip-card" >
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <img src="assets/international press.png" alt="" className='w-[75%]'/>
      <h1>International Press   </h1>
    </div>
    <div className="flip-card-back">
    <h3 className=''>The International Press Committee serves as the media wing of the conference, where delegates act as journalists, photographers, and editors.<br /> <br /> <span className='text-[1.5em]'>Agenda -</span>
    The committee promotes freedom of expression, media ethics, and journalistic integrity, ensuring unbiased coverage while enhancing the overall experience of the event.
      </h3>
    </div>
  </div>
</div>

<div className="flip-card" >
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <img src="assets/iopc.png" alt="" className='w-[75%]'/>
      <h1>International Olympics and Paralympics Committee    </h1>
    </div>
    <div className="flip-card-back">
    <h3 className=''>Oversees global sports governance, ensuring fair play, ethics, and transparency in international sporting events and organizations. It works to promote the values of sportsmanship and inclusivity across the globe.<br /> <br />
    <span className='text-[1.5em]'>Agenda -</span>
    Examining global sports governance on political conflicts, athlete bans, and doping
Scandals, with proposed reforms for better transparency and effectiveness.

      </h3>
    </div>
  </div>
</div>
</div>
   </ListContainer>
  )
}


const ListContainer = styled.div`



  .flip-card {
  background-color: transparent;
  width: 400px;
  height: 400px;
  perspective: 1000px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.flip-card:focus {
    outline: 0;
}

.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner{
  transform: rotateY(180deg);
}
.flip-card-front{
  display: flex;
  justify-content: center;
  text-align: center;
flex-direction: column;

h1{
  color: white;
  font-size: 1.5em;
}
}
.flip-card-front,
.flip-card-back {

  position: absolute;
  width: 100%;
  height: 100%;
}

.flip-card-front {
  /* background: linear-gradient(to left, #4364f7, #6fb1fc); */
  background-color: #290d35;
  color: black;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-back {

  color: white;
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media screen and (max-width:468px) {
    .flip-card {
  background-color: transparent;
  width: 250px;
  height: 250px;
  perspective: 1000px;
}

.flip-card-back{
  font-size: .7em;
  padding: 0.6em;
}

.flip-card-front{
 

h1{
  color: white;
  font-size: .95em;
  padding: 0.5em;
}
}
}

`
export default List
