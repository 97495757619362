import React from 'react'
import Footer from '../layout/Footer'
import { useStateContext } from '../components/Constants/ContextProvider'
import { FaMobile, FaUser } from 'react-icons/fa6'
import { FaUniversity } from "react-icons/fa";
import { IoBookSharp, IoMail } from "react-icons/io5";
import { SiUnitednations } from "react-icons/si";
import { signOut } from 'firebase/auth';
import { auth } from '../components/fire base/FireBase';
import { useNavigate } from 'react-router-dom';

const Profile = () => {

    const {currentUser, studentData, setStudentData, umungData} = useStateContext()
    const navigate = useNavigate()

    //handle logout

    const Logout=()=>{
        signOut(auth)
        setStudentData(null)
        navigate('/')
    }

  return (
    <div className='w-full '>
        <div className='h-screen'>
            <div className='top-[3rem] h-screen relative p-5 flex flex-col md:flex-row justify-evenly items-center'>
                <img src={currentUser.photoURL!} alt="" className='w-40 h-40 md:h-80 md:w-80 rounded-full border-2 border-gray-400'/>
                <div className='text-lg md:text-2xl text-slate-400 flex flex-col gap-5'>
                    <p className='flex items-center gap-3'><FaUser /> Name : {studentData?.name}</p>
                    <p className='flex items-center gap-3'><IoMail /> Email : {studentData?.email}</p>
                    <p className='flex items-center gap-3'><FaMobile /> Contact Number : {studentData?.phoneNumber}</p>
                    <p className='flex items-center gap-3'><FaUniversity /> College : {studentData?.collageName}</p>
                    <p className='flex items-center gap-3'><IoBookSharp /> Course : {studentData?.course}</p>
                    <p className='flex items-center gap-3'><SiUnitednations /> Committee : {umungData?.committeeName}</p>
                </div>
                <button className='bg-[#b868e0] text-white font-bold py-2 px-4 rounded-lg' onClick={()=>Logout()}>
                    Sign Out
                </button>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Profile
