import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FaInstagram } from "react-icons/fa6";
import Letter from './Letter';
const Team = () => {
  return (

<TeamContainer className='w-[75%] m-auto'>
  <h1 className='text-[#bd71e3] text-[4.2em] max-sm:text-[2.1em] font-bold flex justify-center text-center mt-5 '>THE SECRETARIAT</h1>
{/* Secretary General */}
<div className="container">
  <div className="box">
    <div className="imgBx">
      <img src="assets/arnav.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[1.3em] flex justify-center text-center text-[#ffffff]'>Secretary General</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Arnav Tokas</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/arnavvtokas?igsh=NTdxcmg2d2piMjI1">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/dishita.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[1.3em] flex justify-center text-center text-[#ffffff]'>Secretary General</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Dishita Gera</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/_dishitagera?igsh=MW5hdngxNGYwenIyNQ==">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/shubh.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[1.3em] flex justify-center text-center text-[#ffffff]'>Director General</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Shubh Sharma</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/shubhsharma._?igsh=MTlscGpzN2pkenV1Mw==">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/riya.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[1.3em] flex justify-center text-center text-[#ffffff]'>Director General</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Riya Madaan</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/riyyaaa_xo/profilecard/?igsh=cXlmZndzOTNpajg=">Instagram</Link></h3>
    </div>
  </div>
  
  
  
</div>

{/*  */}
<div className="container">
  <div className="box">
    <div className="imgBx">
      <img src="assets/ayush.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[0.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Administration  </h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Ayush Bachheti</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/_ayushwho_?igsh=MW45cHExd2U4NW90OQ==">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/suhani.png" alt=""/>
      <figcaption>
      <h1 className='position bg-[#9153b0]  text-[0.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Administration </h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Suhani Gupta</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="#">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/yash.png" alt=""/>
      <figcaption>
      <h1 className='position bg-[#9153b0]  text-[0.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Videography and Photography </h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Yash Wadhwa</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/kovidhrawat/">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/shivali.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[0.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Delegate Affairs</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Shivali Vohra</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/shivalivohra_?igsh=MWN6aXBndnZjYnA0Ng%3D%3D&utm_source=qr">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/mridul.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[0.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Delegate Affairs</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Mridul Vaid</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/vaidmridul/">Instagram</Link></h3>
    </div>
  </div>
  
  
  
{/* </div> */}


{/*  */}
{/* <div className="container"> */}
  <div className="box">
    <div className="imgBx">
      <img src="assets/yashika.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[0.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Technical Affairs</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Yashika Kaushik</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/yyashika_k?igsh=OTF4amcwM3I1a254">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/ashish.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[0.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Technical Affairs</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Ashish Jangra</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/theashishshadow/">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/satyam.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[0.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Logistics</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Satyam Arora</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/dandy_dono?igsh=OWVkdGpyYXIyYnM5">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/diya.png" alt="diya"/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Logistics</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Diya Kalra </h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/diya.kalraa?igsh=a3lxMG5mNXRvZ2hs">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/chetanya.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Public Relation and Outreach</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Chaitanya Makhija</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="#">Instagram</Link></h3>
    </div>
  </div>
  
  
  
{/* </div> */}


{/*  */}
{/* <div className="container"> */}
  <div className="box">
    <div className="imgBx">
      <img src="assets/vanshika.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[0.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Public Relation and Outreach</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Vanshika Mahajan</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="#">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/pranshu.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Hospitality </h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Pranshu Sharma</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/_pranshusharma._?igsh=NTF2N2Q1OXRmb3Nv&utm_source=qr">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/mehak.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[.9em] flex justify-center text-center text-[#ffffff]'>Under Secretary General for Marketing</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Mehak Gossain</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/mehakgossain_?igsh=MWExdmVlNjF0enNpYQ==">Instagram</Link></h3>
    </div>
  </div>

  <div className="box">
    <div className="imgBx">
      <img src="assets/kashika.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[1.2em] flex justify-center text-center text-[#ffffff] py-2'>Creative Head</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Kashika Jain</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/kashikaa.jain/">Instagram</Link></h3>
    </div>
  </div>


  
  
</div>

{/*  */}
<div className="container ">
  <div className="box">
    <div className="imgBx">
      <img src="assets/kovidh.png" alt=""/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[1em] flex justify-center text-center text-[#ffffff]'>Chief Advisor</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Kovidh Rawat</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/kovidhrawat/">Instagram</Link></h3>
    </div>
  </div>


  <div className="box">
    <div className="imgBx">
      <img src="assets/jasmin.png" alt="jasmin"/>
      <figcaption>

      <h1 className='position bg-[#9153b0]  text-[1em] flex justify-center text-center text-[#ffffff]'>Chief Advisor</h1>
      </figcaption>
    </div>
    <div className="card-footer">
      <h3>Jasmin Lochav</h3>
      <h3 className='flex flex-row justify-center text-center items-center gap-2 text-[.8em]'><FaInstagram /><Link to="https://www.instagram.com/jasminlochav?igsh=MWRhdWpmNXdubTgzcg==">Instagram</Link></h3>
    </div>
  </div>

  
  
</div>

<Letter/>
</TeamContainer>
  )
}

const TeamContainer = styled.div`

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* min-height: 100vh; */
  margin: auto;
  margin-top: 45px;
  gap: 35px;
}

.box {
  /* width: 100%; */
  width: 200px;
  height: 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px 0px 20px 0px;
  /* overflow: hidden; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  transition: 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
z-index: 10000;
    .card-footer {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    .imgBx {
      transform: scale(1.2);
    }
  }

  .imgBx {
    transition: 0.3s ease-in-out;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 20px 20px 0px 0px;
    }
  }

  .card-footer {
    font-size: 20px;
    position: absolute;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    transition: 0.3s ease-in-out;
    width: 100%;
    color: #fff;
    text-align: center;
    padding: 5px 0px;
    
    h3 {
      /* padding: 2.5px 0; */
    }
  }

}
.position{
  border-radius: 0px 0px 10px 10px;
}


@media screen  and (max-width:768px){

  .container {
  margin-top: 15px;
}
}
`
export default Team
