import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Footer from '../layout/Footer'

const Contact  = () => {

    
  return (
    <ContactContainer className="">

<section className="reachUs">
    <div className="h-screen w-full flex flex-wrap content-center  justify-center m-0 justify-items-center flex-col relative ">
    <img src="assets/contact2.png" className="w-full h-full object-cover absolute bgImage"/>
        <div  id="logo" className="items-center  my-4 flex justify-center content-center text-center relative z-2">
            <img className="w-[8.6rem] max-sm:w-[7.9rem] my-3"src="assets/newlogo.png"/>
            <div className="h-[4rem] w-1 bg-white m-5 max-sm:m-3"></div>
            <div className="text-3xl max-sm:text-2xl font-bold text-white">THIRD EDITION</div>
        </div>
        <div className="mb-3 text-center relative z-2">
            <h1 className="text-white font-extrabold text-7xl max-sm:text-[2.8rem]">GET IN TOUCH</h1>
            <h2 className="text-[#b868e0] text-6xl max-sm:text-[2.7rem] mt-2 font-bold">WITH US</h2>
        </div>
        <div id="text">
            <p className="text-center text-white text-[1.1rem] max-sm:text-[0.8em] max-sm:px-3">Want to Get in Touch ?  Here's How You Can Reach Us...</p>
            <p className="text-center text-[#b868e0] underline text-[1rem] max-sm:text-[1em] max-sm:px-3">umung@iitmipu.ac.in</p>
        </div>
     {/* <img className="w-48 h-48 m-16" src="mun.png">  */}
 </div>
    
   </section>
   {/* <h1 className="text-white font-extrabold text-7xl text-center mt-3">CONTACT <a href="#" className="text-fuchsia-700">US</a></h1> */}
   <section  className=" h-full w-full">
     <h1 className=" text-[#ffffff] text-[5em] max-sm:text-[3em]  font-semibold flex justify-center text-center mt-5">
        CONTACT <a href="#" className="text-[#b868e0] mx-3"> US</a></h1> 
    <h2 className="text-center max-sm:text-[1.5em] text-[3.8em] text-[#b868e0] mt-7 font-bold ">Contact For MUN Related Queries</h2>
    <div className="flex flex-wrap justify-evenly max-sm:justify-center text-center my-9 max-sm:gap-[2.5rem]">
         <div className="flex justify-center  text-center flex-col">
            <h3 className="font-semibold max-sm:text-[1.6em] text-3xl text-white mb-3">Secretary General </h3>
            <p className="text-2xl max-sm:text-[1.3em] font-medium text-center text-white"> Arnav Tokas</p>
            <p className="text-xl max-sm:text-[1em] text-center text-white"> +91 96439 35031</p>
        </div>
        <div className="flex justify-center text-center  flex-col">
            <h3 className="font-semibold max-sm:text-[1.6em] text-3xl text-white mb-3" >Secretary General</h3>
            <p className="text-2xl font-medium text-center max-sm:text-[1.3em] text-white">Dishita Gera</p>
            <p className="text-xl text-center max-sm:text-[1em] text-white">+91 93113 42484</p>
        </div>
        <div className="flex justify-center text-center content-center flex-col">
            <h3 className="font-semibold max-sm:text-[1.6em] text-3xl  text-white mb-3">Director General</h3>
            <p className="text-2xl font-medium text-center max-sm:text-[1.3em] text-white"> Shubh Sharma</p>
            <p className="text-xl text-center max-sm:text-[1em] text-white">+91 88002 58078</p>
        </div>
        <div className="flex justify-center text-center content-center flex-col">
            <h3 className="font-semibold max-sm:text-[1.6em] text-3xl  text-white mb-3 ">Director General</h3>
            <p className="text-2xl font-medium text-center max-sm:text-[1.3em] text-white ">Riya Madaan</p>
            <p className="text-xl text-center max-sm:text-[1em] text-white ">+91 87448 99382</p>
        </div>
    </div>
    {/* <h2  className="text-center max-sm:text-[2em] text-[4em] text-[#b868e0] mt-[5rem] font-bold">Contact For Administration Related Queries</h2> */}
        {/* <div className="flex flex-wrap justify-evenly max-sm:gap-[3.5rem] content-center ">
             <div className="flex justify-center content-center flex-col">
            <h3 className="font-semibold text-center text-white max-sm:text-[1.6em] text-[2.9rem] mb-3">President</h3>
            <p className="text-3xl font-medium text-center max-sm:text-[1.3em] text-white">Kanak Rawat</p>
            <p className="text-2xl text-center max-sm:text-[1em] text-white">+91 70119 28985</p>
            
        </div>
        <div className="flex justify-center content-center flex-col">
            <h3 className="font-semibold max-sm:text-[1.6em] text-[2.9rem] text-white mb-3">Vice President</h3>
            <p className="text-3xl font-medium text-center max-sm:text-[1.3em] text-white">Jasmin Lochav</p>
            <p className="text-2xl text-center max-sm:text-[1em] text-white">+91 79822 72622</p>
            
        </div>
    </div> */}
   </section>
   <div className="map">
      <p className='text-center items-center flex justify-center'>Location</p>
      <div id="map" className='w-[100%] text-center justify-center items-center flex'>
      <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d79224.85153473914!2d77.06527153622272!3d28.6514277638802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b5567911807%3A0x996e86fe24144daf!2sInstitute%20of%20Information%20Technology%20and%20Management%20(IITM)!5e0!3m2!1sen!2sin!4v1727263090895!5m2!1sen!2sin"
  width="75%"
  height="500"
  style={{ border: 0 }}
  allowFullScreen
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
></iframe>
      </div>
    </div>
   <Footer/>
   </ContactContainer>
  )
}


const ContactContainer = styled.div`
h1,h2{
    Animation: slideright 1s ease forwards;
    animation-delay: .4s;
}
#logo{
    Animation: slideTop 1s ease forwards;
    animation-delay: .2s;
}

#text{
    Animation: slideright 1s ease forwards;
    animation-delay: .6s;
    z-index: 10000;
}
h2{
    Animation-delay: .5s;
}
.bgImage{
    filter: brightness(0.4);
}


.map {
    /* margin-left: 10%; */
    margin: auto;
   
}

.map p{
    font-size: 2.5rem;
    color: #bd71e3;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 30px;


}

`
export default Contact 
