import React from 'react'
import styled from 'styled-components'

const Letter = () => {
  return (
    <LetterContainer className='mt-[80px]'>
<h1 className='items-center text-center text-[#bd71e3] text-[1em] mb-4 md:text-[3em] max-sm:font-bold font-semibold'>LETTER FROM SECRETARY GENERAL</h1>

<div className='border-[1px] text-white max-sm:w-[100%] w-[60%] m-auto rounded-[10px] border-[#bd71e3] p-4 text-left '>
<p className='mb-5 text-[0.9em] md:text-[1.1em] text-left'>Dear Delegates,</p>
<p className='mb-5 text-[0.6em] md:text-[1.1em]'>We feel ecstatic to extend an invitation to you for उMUNg- Third Edition, a Model United Nations event presented by InternWare- Internship Cell of IITM. उMUNg- Third Edition aims to bring about an experience that fosters critical thinking, intellectual diplomacy, spontaneous resolutions and formidable plans of action. Our objective here is to evaluate and analyze all the aforementioned qualities within the delegates, while delivering a conference full of vitality, spirit,
furthermore designing a perfect amalgamation of enjoyment and altercation.</p>
<p className='mb-5 text-[0.6em] md:text-[1.1em]'>Our mission is to encourage open dialogue and respectful disagreement, empowering individuals to think critically and express their ideas confidently. We envision a future where debate is a vital part of democratic societies, promoting understanding and collaboration among diverse perspectives. By engaging in structured discussions, we strive to develop the next generation of leaders with the inventory needed to tackle global challenges.
</p>
<p className='mb-5 text-[0.6em] md:text-[1.1em]'>उMUNg presents itself as a platform worthy of your debating expertise, while being a place to learn, connect and create unforgettable experiences. We eagerly anticipate your presence at our esteemed event. Join us at उMUNg- Minds in Motion: Model United Nations, Third Edition and become a part of the legacy.</p>

<p className='mb-5 text-[0.6em] md:text-[1.1em]'>Regards <br />
Secretary General</p>
</div>
    </LetterContainer>
  )
}


const LetterContainer = styled.div`

`
export default Letter
