import React, { useState } from 'react';
import Footer from '../layout/Footer';
import styled from 'styled-components';
import axios from 'axios';
import { useStateContext } from '../components/Constants/ContextProvider';
import toast from 'react-hot-toast';
import Loader from '../components/UI/Loader';
import { useNavigate } from 'react-router-dom';

// Define an interface for form data
interface FormData {
  name: string;
  phone: string;
  college: string;
  course: string;
  year: string;
}


const UserForm: React.FC = () => {
  // State to manage form data
  const {currentUser, setStudentData} = useStateContext()
  const [loading,setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const [formData, setFormData] = useState<FormData>({
    name: '',
    phone: '',
    college: '',
    course: '',
    year: ''
  });

  // Handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true)

    await axios.post('https://student-data2024-azdk.vercel.app/api/students/addStudent',{
      name:formData.name,
      email:currentUser.email,
      phoneNumber:formData.phone,
      uid:currentUser.uid,
      collageName:formData.college,
      course:formData.course,
      year:formData.year,
    }).then((res)=>{
      toast.success('🥳 Student Registered Successfully..!')
      setUser()
      console.log(res)
    }).catch((error)=>{
      console.log(error)
      toast.error("😰 Some Error Uncured While Registering the Student")
    })

    setFormData({
      name: '',
      phone: '',
      college: '',
      course: '',
      year: ''
    })
    setLoading(false)
    navigate('/') // You can handle form submission here
  };

  //student data init

  const setUser =async()=>{
    if(currentUser !== null ){
      setLoading(true)
      await axios.get(`https://student-data2024-eosin.vercel.app/api/students/getRequest?uid=${currentUser.uid!}`).then((res)=>setStudentData(res.data.StudentData)).catch((error)=>console.log(error))
      setLoading(false)
    }
  }

  return (
    <FormContainer className='relative top-[8rem]' >
    { loading && 
    <div className='h-screen flex justify-center items-center'>
      <Loader/>
    </div>
    }
    {(currentUser !== null && loading === false) && <form onSubmit={handleSubmit} className="max-w-md m-auto p-6 bg-white shadow-md rounded-md space-y-6">
      <div>
        <label className="block text-sm font-medium">Name</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
          required
          placeholder='Name'
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Phone Number</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
          required
          placeholder='Phone Number'
        />
      </div>

      <div>
        <label className="block text-sm font-medium">College Name</label>
        <select
          name="college"
          value={formData.college}
          onChange={handleChange}
          className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
          required
        >
          <option value="" disabled>Select College</option>
          <option value="IITM">IITM</option>
          <option value="IPITM">IPITM (MDU)</option>
          <option value="IINTM">IINTM</option>
          <option value="OTHER">Other</option>
        </select>

        {formData.college === 'OTHER' && (
          <div className="mt-2">
            <label className="block text-sm font-medium">Enter Your College Name</label>
            <input
              type="text"
              name="customCollege"
              defaultValue={formData.college}
              onChange={handleChange}
              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              required={formData.college === 'OTHER'}
            />
          </div>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium">Course</label>
        <input
          type="text"
          name="course"
          value={formData.course}
          onChange={handleChange}
          className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
          required
          placeholder='Course'
        />
      </div>

      <div>
        {/* <label className="block text-sm font-medium">Year</label>
        <input
          type="text"
          name="year"
          value={formData.year}
          onChange={handleChange}
          className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
          required
        /> */}

{/* ashutosh */}
<label htmlFor="year" className="text-black block text-sm semi-bold">Year</label>
<input 
required value={formData.year } onChange={(e)=>{ (Number(e.target.value) >= 0  && Number(e.target.value) <= 12) ? handleChange(e) : toast.error("The Imputed Year is Invalid") }} 
type="text"
name="year" 
id="year" 
className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300" placeholder="Year"
/>
{/* ashutosh */}

      </div>

      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
        onClick={()=>handleChange}
      >
        Submit
      </button>
    </form>}

    <Footer/>
    </FormContainer>
  );
};

const FormContainer = styled.div`
    

`

export default UserForm;
