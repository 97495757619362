// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
// import required modules
import styled from 'styled-components';
// import required modules
import { EffectCube, Pagination ,Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';



const Glimpses = () => {
  return (
    <GlimpsesContainer className="mt-[50px] ">
      <div className='flex flex-col justify-center text-center font-ubuntu mb-5'>
      <h1 className='text-[#bd71e3] md:text-[5em] text-[2.4em]'> Glimpses from <span className='text-white'>2023 </span></h1>

      <p className='text-white text-[0.8em] max-md:px-10 w-full m-auto my-3 md:w-[85%] md:text-[1.2em]'>The inaugural edition of उMUNg - Minds In Motion was a key milestone in fostering diplomatic discourse and leadership among students, attracting enthusiastic participants for debates on global issues. It set a strong foundation for future editions.</p>

      <p className='text-white text-[0.8em] max-md:px-10 w-full m-auto my-3 md:w-3/4 md:text-[1.2em]'>Building on this success, the Second Edition introduced dynamic committees, broader topics, and expanded participation, further enhancing leadership and diplomacy skills. With over 400 participants, both editions cemented उMUNg’s reputation as a premier platform for aspiring diplomats.</p>
     
     <div className='flex justify-center text-center items-center my-2 '>
      <h1 className='click border-[2px] border-[#bd71e3] text-white p-3 hover:bg-[#bd71e3]  text-[.6em]  md:text-[1.3em] font-semibold md:font-bold font-ubuntu'><Link to="https://www.iitminternware.com/2023-24/UMUNG/index.php" target='_blank'>Click Here to Know More About उMUNg 2023</Link></h1>
     </div>
      </div>

    {/* <Swiper navigation={true} loop={true} modules={[Navigation]} className="mySwiper ">
        <SwiperSlide><img src="./assets/3.png" alt="" /></SwiperSlide>
        <SwiperSlide><img src="./assets/3.png" alt="" /></SwiperSlide>
        <SwiperSlide><img src="./assets/3.png" alt="" /></SwiperSlide>
        <SwiperSlide><img src="./assets/3.png" alt="" /></SwiperSlide>
        <SwiperSlide><img src="./assets/3.png" alt="" /></SwiperSlide>
        <SwiperSlide><img src="./assets/3.png" alt="" /></SwiperSlide>
        <SwiperSlide><img src="./assets/3.png" alt="" /></SwiperSlide>
        <SwiperSlide><img src="./assets/3.png" alt="" /></SwiperSlide>
        <SwiperSlide><img src="./assets/3.png" alt="" /></SwiperSlide>
      </Swiper> */}
 <Swiper
        effect={'cube'}
        grabCursor={true}
        loop={true}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        // pagination={true}
        autoplay={{
          delay: 2500, // Change this to the desired delay (in milliseconds)
          disableOnInteraction: false, // Keeps autoplay working after user interaction
        }}
        modules={[EffectCube, Pagination,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src="assets/umung3.png" alt='image'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src="assets/umung1.png" alt='image'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src="assets/umung2.png" alt='image'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src="assets/umung4.png" alt='image'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src="assets/umung5.png" alt='image'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src="assets/umung6.png" alt='image'/>
        </SwiperSlide>
      </Swiper>
      
    </GlimpsesContainer>
  )
}

const GlimpsesContainer = styled.div`
.click{
  transition: 0.3s ease-in-out;
}
/* .swiper {
  width: 60%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 700px;
  object-fit: cover/center;
} */


  .swiper {
  width: 500px;
  height: 500px;
}

@media screen  and (max-width :768px){
  
  .swiper {
  width: 300px;
  height: 300px;
}
}
.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}



`
export default Glimpses
