import React from 'react'
import styled from 'styled-components'

const Why = () => {
  return (
   <WhyContainer>
    <h1 className='text-[5em] max-sm:text-[2.8em] md:text-[6em] text-[#bd71e3] text-center font-semibold'>Why Sponsor Us?</h1>
    <p className='text-[1em] md:text-[1.4em] my-6 text-center max-sm:w-[80%] text-white w-[65%] mx-auto'>
    Partner with InternWare and gain access to a vibrant and tech-savvy audience. By sponsoring उMUNg - Minds In Motion Third Edition, you can showcase your brand to a dynamic group of young, innovative minds. Build lasting relationships through networking and position your company as a unique and problem-solving brand. Let's collaborate to create an unforgettable experience that benefits us all.
</p>

    
   </WhyContainer>
  )
}

const WhyContainer = styled.div`

`
export default Why
