import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Footer = () => {
  return (
    <FooterConatiner className='bg-[#16071d]'>

    <footer className='font-ubuntu'>
    <div className="footer-container1">
        <div className="container1-i1">
            <img src="assets/newlogo.png" alt="umung" className='max-md:w-[120px] w-[135px] md:w-[185px]'/>
            <div className="line1"></div>
            <h1>THIRD EDITION</h1>
        </div>
        <p className='text-[0.9em] md:text-[1em] max-sm:text-[.8em]'>Connect. Discover. Debate. Improve. उMUNg is your chance!</p> 
        <button className="text-[white] bg-[#190721] hover:bg-[#8633aa] hover:border-[#8633aa] p-2 border-[2px] border-[white] rounded text-xl font-ubuntu ">
          <Link to="/Contact" >
            Contact Us
          </Link>
        </button>          
    </div>
    <div className="footer-container2">
        
    </div>
</footer>
    </FooterConatiner>
  )
}

const FooterConatiner = styled.div`
  
 /*FOOTER*/

 footer {
    min-height: 20vh;
    padding: 40px 0;
    margin-top: 50px;
  }

  .footer-container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    width: 40%;
    margin: auto;
  }

  .footer-container1 p {
    color: white;
    text-align: center;
    margin: 15px 0;
  }

  .container1-i1 {
    display: flex;
    align-items: center;
    justify-content: left;
  }

  /* .container1-i1 img {
    width: 200px;
  } */

  .container1-i1 h1 {
    color: white;
    font-size: 2.5rem;
  }

  .container1-i1 .line1 {
    margin: 20px;
    width: 4px;
    background-color: white;
    height: 60px;
  }

@media screen and (max-width:786px) {
  .footer-container1 {
    
    margin-left: 0px;
    width: 100%;
    margin: 0px;
  }
  .container1-i1 {
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  .container1-i1 h1 {
    color: white;
    font-size: 1.4rem;
  }
  .container1-i1 .line1 {
    /* margin: 20px; */
    margin-left: 20px;
    margin-right: 20px;
    width: 3px;
    background-color: white;
    height: 40px;
  }
}
`
export default Footer
