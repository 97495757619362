import React from 'react'
import styled from 'styled-components'
import Team from '../components/Secretariat/Team'
import Footer from '../layout/Footer'

const Secretariat = () => {
  return (
    

    <SecretariatContainer>
      <section className="home" id="home">
      <div className="max-width">
        <div className="home-content">
          <div className="committeeBanner-container1">
            <img src="assets/newlogo.png" className='z-[999]' alt="" />
            <div className="line2"></div>
            <h1 >THIRD EDITION</h1>
          </div>
          <div className="text-2">
            The Organizational <br />
            <span> Backbone</span>
          </div>
        </div>
        <div className="colored-cover"></div>
      </div>
    </section>
<Team/>


<Footer/>
    </SecretariatContainer>
  )
}

const SecretariatContainer = styled.div`
  
  .home {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("assets/SEC2.png") no-repeat center;
    /* background-color: #14404D; */
    height: 100vh;
    color: #000000;
    min-height: 50vh;
    background-size: cover;
    background-attachment: fixed;
    font-family: 'Ubuntu', sans-serif;
    /* font-family: Bebas; */
}

.home .max-width {
    width: 100%;
    display: flex;
}

.home .home-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.home .home-content .text-2 {
    font-size: 5rem;
    font-weight: 800;
    position: relative;
    z-index: 999;
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-style: normal;
    text-align: center;
    justify-content: center;
    line-height: 1;
}

.home .home-content .text-3 {
    font-size: 3rem;
    text-align: center;
    justify-content: center;
    z-index: 999;
    color: white;
    font-weight: 600;
}

.home .home-content .text-2 span {
    color: #9f13ce;
}

.proposalDownload {
    color: #000000;
    margin-top: 40px;
    font-size: 6vh;
    z-index: 999;
}

.colored-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000000;
    opacity: 0.6;
    /* Adjust the opacity as needed */
}
.committeeBanner-container1 {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: relative;
    z-index: 999;
}

.committeeBanner-container1 img {
    width: 150px;
}

.committeeBanner-container1 h1 {
    color: white;
    font-size: 2rem;
}

.committeeBanner-container1 .line2 {
    margin: 20px;
    width: 4px;
    background-color: white;
    height: 50px;
}

@media screen  and (max-width:768px){
  .home .home-content .text-2 {
    font-size: 2.5rem;
    font-weight: 800;
    position: relative;
    z-index: 999;
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-style: normal;
    text-align: center;
    justify-content: center;
    /* line-height: 0; */
}

.home .home-content .text-3 {
    font-size: 2rem;
    text-align: center;
    justify-content: center;
    z-index: 999;
    color: white;
    font-weight: 600;
}

.home .home-content .text-2 span {
    color: #9f13ce;
} 
}
.committeeBanner-container1 .line2 {
    margin: 15px;
    width: 4px;
    background-color: white;
    height: 50px;
}
.committeeBanner-container1 img {
    width: 170px;
}

.committeeBanner-container1 h1 {
    color: white;
    font-size: 1.4rem;
    font-weight: 700;
}
`

export default Secretariat
