import React from 'react'
import styled from 'styled-components'
import List from '../components/committees/List'
import Footer from '../layout/Footer'
import { Link } from 'react-router-dom'

const Committees = () => {
  return (
  <CommitteesContainer>
  <section className="home" id="home">
      <div className="max-width">
        <div className="home-content">
          <div className="committeeBanner-container1">
            <img src="assets/newlogo.png" className='z-[999]' alt="" />
            <div className="line2"></div>
            <h1>THIRD EDITION</h1>
          </div>
          <div className="text-2">
          Committees  at a <span> Glance</span> 
          
          </div>
          {/* martics ashish */}
          {/* <label htmlFor="inputBox" className="border rounded-2xl text-[1.4em] z-[9898989898989]  max-sm:text-[1.1em]  mb-2 mt-[40px] text-white bg-[#ffffff] text-center  font-semibold py-2 px-4">
      <Link to="https://docs.google.com/spreadsheets/d/1yyqT61NWVBA3m5XwN44zFmE0L42GobLSugqVVk2cxtk/edit" className='bg-white text-black font-bold ' target='_blank'>
          Select your matrice from here!
          </Link>
      </label> */}

          {/* matrics ashish */}
        </div>
        <div className="colored-cover"></div>
      </div>
    </section>

    <List/>
    <Footer/>
  </CommitteesContainer>
  )
}


const CommitteesContainer = styled.div`
  .home {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("/assets/MUN6.jpg") no-repeat center;
    height: 100vh;
    color: #000000ed;
    min-height: 50vh;
    background-size: cover;
    background-attachment: fixed;
    font-family: 'Ubuntu', sans-serif;
    /* font-family: Bebas; */
}

.home .max-width {
    width: 100%;
    display: flex;
}

.home .home-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.home .home-content .text-2 {
    font-size: 5rem;
    font-weight: 800;
    position: relative;
    z-index: 999;
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-style: normal;
    text-align: center;
    justify-content: center;
    line-height: 1;
}

.home .home-content .text-3 {
    font-size: 3rem;
    text-align: center;
    justify-content: center;
    z-index: 999;
    color: white;
    font-weight: 600;
}

.home .home-content .text-2 span {
    color: #a52ccd;
}

.proposalDownload {
    color: #000000;
    margin-top: 40px;
    font-size: 6vh;
    z-index: 999;
}

.colored-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000000;
    opacity: 0.6;
    /* Adjust the opacity as needed */
}
.committeeBanner-container1 {
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
    z-index: 999;
}

.committeeBanner-container1 img {
    width: 150px;
}

.committeeBanner-container1 h1 {
    color: white;
    font-size: 1.5rem;
}

.committeeBanner-container1 .line2 {
    margin: 20px;
    width: 4px;
    background-color: white;
    height: 50px;
}

@media screen  and (max-width:768px){
  .home .home-content .text-2 {
    font-size: 3rem;
    font-weight: 700;
    position: relative;
    z-index: 999;
    /* font-family: 'Montserrat', sans-serif; */
    color: white;
    font-style: normal;
    text-align: center;
    justify-content: center;
    /* line-height: 1; */
}

.home .home-content .text-3 {
    font-size: 2rem;
    text-align: center;
    justify-content: center;
    z-index: 999;
    color: white;
    font-weight: 600;
}

.home .home-content .text-2 span {
    color: #d08be7;
} 
}
  
`
export default Committees
