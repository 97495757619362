import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Committees = () => {
  return (
    <CommitteesContainer className='mt-20'>
<section>
<div className="font-['ubuntu']  text-center items-center">
<h1 className='text-[#bd71e3] md:text-[5em] text-[2.1em] max-sm:font-semibold'> <span>3<sup>rd</sup> Edition</span> Committees</h1>
        </div>
        <section className='container max-md:mx-5'>
          {/* 1 */}
<div className='w-full  items-center flex flex-col justify-center text-center'>
  <div>
    <img src="assets/logoUNGA.png" alt="UNGA" className='max-sm:w-[100px] w-[150px] md:w-[180px]' />
  </div>

  <div className='flex flex-col justify-center text-center font-ubuntu text-white'>
    <h1 className=' text-[.9em] md:text-[1.5em]'>United Nations General Assembly 
      <br />
      <span> (UNGA)</span>
   </h1>
      <h1 className=' m-auto border-[2px] max-sm:border-[1px] border-white w-2/4 md:w-2/4 text-[.8em] md:text-[1.5em] text-white hover:bg-[#bd71e3] hover:border-[#bd71e3]'><Link to="/Committees">READ MORE</Link></h1>
  </div>
</div>

          </section>
<section className='container max-md:mx-5'>
  
{/* 2 */}
<div className='w-full items-center flex flex-col justify-center text-center'>
  <div>
    <img src="assets/UNHRC.png" alt="UNHRC" className='max-sm:w-[100px] w-[150px] md:w-[180px]' />
  </div>

  <div className='flex flex-col justify-center text-center font-ubuntu text-white'>
    <h1 className=' text-[.9em] md:text-[1.5em]'>United Nations Human Rights Council
      <br />
      <span> (UNHRC)</span>
   </h1>
      <h1 className=' m-auto border-[2px] max-sm:border-[1px] border-white w-2/4 md:w-2/4 text-[.8em] md:text-[1.5em] text-white hover:bg-[#bd71e3] hover:border-[#bd71e3]'><Link to="/Committees">READ MORE</Link></h1>
  </div>
</div>

{/* 3 */}

<div className='w-full items-center flex flex-col justify-center text-center'>
  <div>
    <img src="assets/logoUNSC.png" alt="(UNSC)" className='max-sm:w-[100px] w-[150px] md:w-[180px]' />
  </div>

  <div className='flex flex-col justify-center text-center font-ubuntu text-white'>
    <h1 className=' text-[.9em] md:text-[1.5em]'>United Nations Security Council
      <br />
      <span> (UNSC)</span>
   </h1>
      <h1 className=' m-auto border-[2px] max-sm:border-[1px] border-white w-2/4 md:w-2/4 text-[.8em] md:text-[1.5em] text-white hover:bg-[#bd71e3] hover:border-[#bd71e3]'><Link to="/Committees">READ MORE</Link></h1>
  </div>
</div>
{/* 4 */}
<div className='w-full items-center flex flex-col justify-center text-center'>
  <div>
    <img src="assets/loksabhafinall.png" alt="loksabha" className='max-sm:w-[100px] w-[150px] md:w-[180px]' />
  </div>

  <div className='flex flex-col justify-center text-center font-ubuntu text-white'>
    <h1 className=' text-[.9em] md:text-[1.5em]'>Lok Sabha
      <br />
      <span> (Lok Sabha)</span>
   </h1>
   <h1 className=' m-auto border-[2px] max-sm:border-[1px] border-white px-2 text-[.8em] md:text-[1.5em] text-white hover:bg-[#bd71e3] hover:border-[#bd71e3]'><Link to="/Committees">READ MORE</Link></h1>
  </div>
</div>

{/* 5 */}
<div className='w-full items-center flex flex-col justify-center text-center'>
  <div>
    <img src="assets/nato logo finalalal.png" alt="nato" className='max-sm:w-[100px] w-[150px] md:w-[180px]' />
  </div>

  <div className='flex flex-col justify-center text-center font-ubuntu text-white'>
    <h1 className=' text-[.9em] md:text-[1.5em]'>North Atlantic Treaty Organization 
      <br />
      <span> (NATO)</span>
   </h1>
      <h1 className=' m-auto border-[2px] max-sm:border-[1px] border-white w-2/4 md:w-2/4 text-[.8em] md:text-[1.5em] text-white hover:bg-[#bd71e3] hover:border-[#bd71e3]'><Link to="/Committees">READ MORE</Link></h1>
  </div>
</div>

{/* 6 */}
<div className='w-full items-center flex flex-col justify-center text-center'>
  <div>
    <img src="assets/international press.png" alt="international press" className='max-sm:w-[100px] w-[150px] md:w-[180px]' />
  </div>

  <div className='flex flex-col justify-center text-center font-ubuntu text-white'>
    <h1 className=' text-[.9em] md:text-[1.5em]'>International Press
      <br />
      <span> (IP)</span>
   </h1>
   <h1 className=' m-auto border-[2px] max-sm:border-[1px] border-white px-2 text-[.8em] md:text-[1.5em] text-white hover:bg-[#bd71e3] hover:border-[#bd71e3]'><Link to="/Committees">READ MORE</Link></h1>
  </div>
</div>

{/* 7 */}
<div className='w-full items-center flex flex-col justify-center text-center'>
  <div>
    <img src="assets/iopc.png" alt="iopc" className='max-sm:w-[100px] w-[150px] md:w-[18 0px]' />
  </div>

  <div className='flex flex-col justify-center text-center font-ubuntu text-white'>
    <h1 className=' text-[.9em] md:text-[1.5em]'>International Olympic and Paralympic Committee
      <br />
      <span> (IOPC)</span>
   </h1>
      <h1 className=' m-auto border-[2px] max-sm:border-[1px] border-white w-2/4 md:w-2/4 text-[.8em] md:text-[1.5em] text-white hover:bg-[#bd71e3] hover:border-[#bd71e3]'><Link to="/Committees">READ MORE</Link></h1>
  </div>
</div>



</section>


</section>
    </CommitteesContainer>
  )
}


const CommitteesContainer = styled.div`
  .container{
    width: 100%;
    /* max-width: 1000px; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 5em;
    margin: auto;
  }
 
  @media screen and (max-width:788px){
    .container{
      gap:3em ;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    
    }
  }
  @media screen and (max-width:468px){
    .container{
      gap:2em ;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    
    }
  }
`
export default Committees
