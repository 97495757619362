import { onAuthStateChanged, User } from 'firebase/auth'
import { createContext, useContext, useEffect, useState, ReactNode, SetStateAction } from 'react'
import { auth } from '../fire base/FireBase'

interface type{
    currentUser:User,
    studentData: any,
    setStudentData: React.Dispatch<SetStateAction<any>>,
    umungData:any,
    setUmungData: React.Dispatch<SetStateAction<any>>
}

export const StateContext = createContext<type>({} as any)

export const ContextProvider = ({children}:{children:ReactNode}) => {

    const [currentUser,setCurrentUser] = useState<User>({} as any)
    const [studentData,setStudentData] = useState<any>(null)
    const [umungData,setUmungData] = useState<any>(null)


    useEffect(()=>{
        const unsubscribe = onAuthStateChanged(auth,(user)=>{
            setCurrentUser(user!)
        })
        return ()=>unsubscribe();
    },[])

  return (
   <StateContext.Provider value={{currentUser, studentData, setStudentData, umungData, setUmungData}}>
        {children}
   </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext);
