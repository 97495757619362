import React from 'react'
import styled from 'styled-components'

const current = ()=> {
    return(
        <CurrentContainer className='text-center flex justify-center flex-col w-full'>
        <h1 className='text-[6em] max-sm:text-[2.7em] md:text-[6em]  text-[#bd71e3] text-center font-semibold'>Current Sponsors</h1>
           <p className='text-[1em] md:text-[1.3em] my-3 text-center max-sm:w-[80%] text-white w-[65%] mx-auto'>The Third Edition of उMUNg - Minds In Motion proudly acknowledges the invaluable support of our sponsors, whose partnerships bring unmatched energy, resources, and excitement to this event. We extend our gratitude to HP Omen, Dublieu, Fruitchill, Unstop, Angrejjan, Choco Mocha, Caffeine Corner, Extra with Elegance, Kitchenoma, Kwickpic, Annapurna's Rasoi, Crimson Aura, Color Up, Singh n Ming, IMS, and Career Launcher.</p>
       
           <p className='text-[1em] md:text-[1.3em] mb-3 text-center max-sm:w-[80%] text-white w-[65%] mx-auto'>We are grateful for their invaluable support and partnership, which enables us to create an impactful, engaging, and memorable experience at the Third Edition of उMUNg - Minds In Motion.</p>

           <p className='text-[1em] md:text-[1.3em] mb-3 text-center max-sm:w-[80%] text-white w-[65%] mx-auto'>With their dedicated support, we deliver an experience that is impactful, engaging, and unforgettable for all participants. Together, we are confident in our mission to foster learning, creativity, and connection at उMUNg.</p>
       
           <section className='container '>
           <img src="assets/dublieu1.png" alt=""  className='   m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%] '/>
       <img src="assets/unstop.png" alt=""  className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]'/>
       <img src="assets/youth_delhi.png" alt=""  className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]'/>
       <img src="assets/crimson_aura.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/ims.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/kwikpic new.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/kitchenoma.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/choco_mocha.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/color_up.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/extra_with_elegance.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/career_launcher.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/angrejjan new.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/caffiene corner.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/via chennai.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/cue_brew.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/lazeez new.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
       <img src="assets/singh ming new.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
             </section>
             
          </CurrentContainer>
    )
}

const CurrentContainer = styled.div`
    .container{
  margin-top: 50px !important;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 3em;
    margin: auto;
  }
 
  @media screen and (max-width:788px){
    .container{
      gap:2em ;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    
    }
  }
  @media screen and (max-width:468px){
    .container{
      gap:1.7em;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    
    }
  }
`

export default current
