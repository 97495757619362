import React,{useState} from 'react'
import styled from 'styled-components'
import Navbar from './Navbar'
// import UseWindowDimension from '../hooks/UseWindowDimension'
import Burger from './Burger'

const Layout = ({children} : {children:React.ReactNode}) => {

    // const {width}  = UseWindowDimension();
    const [openMenu,setMenue] = useState<boolean>(false)

  return (
    <LayoutContainer>
       <Navbar open={setMenue} isopen={openMenu} /> 
        {/* { width < 868 && <Burger />} */}
        {
           openMenu && <Burger />

        }
        {children}
       
    </LayoutContainer>
  )
}

const LayoutContainer = styled.div`
    
`

export default Layout



